import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "desktop-only"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_MoreJobs = _resolveComponent("MoreJobs")!
  const _component_JobConfiguration = _resolveComponent("JobConfiguration")!
  const _component_MaargJobConfiguration = _resolveComponent("MaargJobConfiguration")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_menu_button, { slot: "start" }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Inventory")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("main", null, [
            _createElementVNode("section", null, [
              _createVNode(_component_ion_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_card_header, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Adjustments")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, {
                    button: "",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.viewJobConfiguration({ id: 'HARD_SYNC', status: _ctx.getJobStatus(_ctx.jobEnums['HARD_SYNC'])}))),
                    detail: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Hard sync")), 1)
                        ]),
                        _: 1
                      }),
                      (!_ctx.isLoading)
                        ? (_openBlock(), _createBlock(_component_ion_label, {
                            key: 0,
                            slot: "end"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getTemporalExpression('HARD_SYNC')), 1)
                            ]),
                            _: 1
                          }))
                        : (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                            key: 1,
                            style: {"width":"30%"},
                            animated: ""
                          }))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, {
                    button: "",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.viewJobConfiguration({ id: 'IMP_PROD_FACILITY', status: _ctx.getJobStatus(_ctx.jobEnums['IMP_PROD_FACILITY'])}))),
                    detail: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Import product facility")), 1)
                        ]),
                        _: 1
                      }),
                      (!_ctx.isLoading)
                        ? (_openBlock(), _createBlock(_component_ion_label, {
                            key: 0,
                            slot: "end"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getTemporalExpression('IMP_PROD_FACILITY')), 1)
                            ]),
                            _: 1
                          }))
                        : (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                            key: 1,
                            style: {"width":"30%"},
                            animated: ""
                          }))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, { lines: "none" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, _toDisplayString(_ctx.translate("Performing a hard sync from HotWax Commerce to Shopify is useful for eliminating any discrepencies.")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_card_header, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Webhooks")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, { lines: "none" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_toggle, {
                        disabled: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE),
                        checked: _ctx.isInventoryLevelUpdated,
                        onIonChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateWebhook($event['detail'].checked, 'INVENTORY_LEVEL_UPDATE'))),
                        color: "secondary"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate("Inventory level update")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["disabled", "checked"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_card_header, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Feed")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, {
                    button: "",
                    detail: "",
                    disabled: !_ctx.isMaargJobFound('GNRT_SHIP_RCPT_FEED'),
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.viewMaargJobConfiguration('GNRT_SHIP_RCPT_FEED')))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Generate Shipments Receipt Feed")), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_label, { slot: "end" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.isMaargJobFound('GNRT_SHIP_RCPT_FEED') ? _ctx.getMaargJobStatus("GNRT_SHIP_RCPT_FEED") : _ctx.translate("Not found")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["disabled"]),
                  _createVNode(_component_ion_item, {
                    button: "",
                    detail: "",
                    disabled: !_ctx.isMaargJobFound('GEN_INV_VAR_FEED'),
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.viewMaargJobConfiguration('GEN_INV_VAR_FEED')))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Generate inventory variance feed")), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_label, { slot: "end" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.isMaargJobFound('GEN_INV_VAR_FEED') ? _ctx.getMaargJobStatus("GEN_INV_VAR_FEED") : _ctx.translate("Not found")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["disabled"]),
                  _createVNode(_component_ion_item, {
                    button: "",
                    detail: "",
                    disabled: !_ctx.isMaargJobFound('GEN_INVCYC_COUNT_VAR_FEED'),
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.viewMaargJobConfiguration('GEN_INVCYC_COUNT_VAR_FEED')))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Generate inventory cycle count variance feed")), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_label, { slot: "end" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.isMaargJobFound('GEN_INVCYC_COUNT_VAR_FEED') ? _ctx.getMaargJobStatus("GEN_INVCYC_COUNT_VAR_FEED") : _ctx.translate("Not found")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["disabled"])
                ]),
                _: 1
              }),
              (_ctx.getMoreJobs(_ctx.jobEnums, _ctx.enumTypeId).length)
                ? (_openBlock(), _createBlock(_component_MoreJobs, {
                    key: 0,
                    jobs: _ctx.getMoreJobs(_ctx.jobEnums, _ctx.enumTypeId)
                  }, null, 8, ["jobs"]))
                : _createCommentVNode("", true)
            ]),
            (_ctx.isDesktop)
              ? _withDirectives((_openBlock(), _createElementBlock("aside", _hoisted_1, [
                  (_ctx.currentJob)
                    ? (_openBlock(), _createBlock(_component_JobConfiguration, {
                        status: _ctx.currentJobStatus,
                        type: _ctx.freqType,
                        key: _ctx.currentJob
                      }, null, 8, ["status", "type"]))
                    : (Object.keys(_ctx.currentMaargJob).length)
                      ? (_openBlock(), _createBlock(_component_MaargJobConfiguration, { key: _ctx.currentMaargJob }))
                      : _createCommentVNode("", true)
                ], 512)), [
                  [_vShow, _ctx.currentJob || Object.keys(_ctx.currentMaargJob).length]
                ])
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}