import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c1f58f1a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "actions desktop-only" }
const _hoisted_2 = { class: "more-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", null, [
      _createVNode(_component_ion_item, { lines: "none" }, {
        default: _withCtx(() => [
          _createElementVNode("h1", null, _toDisplayString(_ctx.currentMaargJob?.enumDescription ? _ctx.currentMaargJob.enumDescription : _ctx.currentMaargJob?.jobName), 1),
          (_ctx.isRefreshRequired)
            ? (_openBlock(), _createBlock(_component_ion_button, {
                key: 0,
                fill: "outline",
                slot: "end",
                onClick: _ctx.refreshCurrentJob
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    icon: _ctx.refreshOutline,
                    slot: "icon-only"
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true),
          (_ctx.currentMaargJob.paused === 'N' && _ctx.currentMaargJob?.nextExecutionDateTime && !_ctx.isRefreshRequired)
            ? (_openBlock(), _createBlock(_component_ion_badge, {
                key: 1,
                slot: "end",
                color: "dark"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("running")) + " " + _toDisplayString(_ctx.timeTillJob(_ctx.currentMaargJob.nextExecutionDateTime)), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_ion_list, null, {
        default: _withCtx(() => [
          (_ctx.currentMaargJob.description)
            ? (_openBlock(), _createBlock(_component_ion_item, {
                key: 0,
                lines: "none"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, _toDisplayString(_ctx.currentMaargJob.description), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                slot: "start",
                icon: _ctx.timeOutline
              }, null, 8, ["icon"]),
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Next run")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_label, { slot: "end" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.currentMaargJob.paused === 'N' ? _ctx.getDateAndTime(_ctx.currentMaargJob.nextExecutionDateTime) : "-"), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, {
            detail: "",
            button: "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openScheduleModal()))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                slot: "start",
                icon: _ctx.timerOutline
              }, null, 8, ["icon"]),
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getCronString(_ctx.selectedCronExpression) || _ctx.selectedCronExpression), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, { lines: "none" }, {
            default: _withCtx(() => [
              (!Object.keys(_ctx.generateCustomParameters).length)
                ? (_openBlock(), _createBlock(_component_ion_chip, {
                    key: 0,
                    onClick: _ctx.openJobCustomParameterModal,
                    outline: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: _ctx.addOutline }, null, 8, ["icon"]),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate('Add custom parameters')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : (_openBlock(), _createBlock(_component_ion_row, { key: 1 }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.generateCustomParameters, (value, name) => {
                        return (_openBlock(), _createBlock(_component_ion_chip, {
                          onClick: _ctx.openJobCustomParameterModal,
                          outline: "",
                          color: value ? undefined :'danger',
                          key: name
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(name) + ": " + _toDisplayString(value), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick", "color"]))
                      }), 128))
                    ]),
                    _: 1
                  })),
              _createVNode(_component_ion_button, {
                onClick: _ctx.openJobCustomParameterModal,
                id: "open-modal",
                slot: "end",
                fill: "clear"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    slot: "icon-only",
                    icon: _ctx.listCircleOutline
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createVNode(_component_ion_button, {
            size: "small",
            fill: "outline",
            color: "medium",
            disabled: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translate("Skip once")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_button, {
            size: "small",
            fill: "outline",
            color: "danger",
            disabled: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE) || _ctx.currentMaargJob.paused === 'Y' || _ctx.isRefreshRequired,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.cancelJob(_ctx.currentMaargJob)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translate("Disable")), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _createElementVNode("div", null, [
          (_ctx.currentMaargJob.paused === 'Y')
            ? (_openBlock(), _createBlock(_component_ion_button, {
                key: 0,
                disabled: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE),
                size: "small",
                fill: "outline",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.enableJob()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Enable")), 1)
                ]),
                _: 1
              }, 8, ["disabled"]))
            : (_openBlock(), _createBlock(_component_ion_button, {
                key: 1,
                disabled: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE) || _ctx.isRefreshRequired,
                size: "small",
                fill: "outline",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.saveChanges()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Save changes")), 1)
                ]),
                _: 1
              }, 8, ["disabled"]))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ion_item, {
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.viewJobHistory(_ctx.currentMaargJob))),
        button: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, {
            slot: "start",
            icon: _ctx.timeOutline
          }, null, 8, ["icon"]),
          _createTextVNode(" " + _toDisplayString(_ctx.translate("History")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_ion_item, {
        disabled: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE),
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.runNow())),
        button: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, {
            slot: "start",
            icon: _ctx.flashOutline
          }, null, 8, ["icon"]),
          _createTextVNode(" " + _toDisplayString(_ctx.translate("Run now")), 1)
        ]),
        _: 1
      }, 8, ["disabled"]),
      _createVNode(_component_ion_item, {
        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.copyJobInformation(_ctx.currentMaargJob))),
        button: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, {
            slot: "start",
            icon: _ctx.copyOutline
          }, null, 8, ["icon"]),
          _createTextVNode(" " + _toDisplayString(_ctx.translate("Copy details")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_ion_item, { button: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, {
            slot: "start",
            icon: _ctx.pinOutline
          }, null, 8, ["icon"]),
          _createVNode(_component_ion_checkbox, { disabled: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Pin job")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ], 64))
}